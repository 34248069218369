<template>
    <div>
        <app-layout>
            <template v-slot:header>
                <Header :title="$t('classrooms')"
                        :isNewButton="checkPermission('classroom_store')"
                        :isColumns="true"
                        @new-button-click="createFormShow"
                        @filter-div-status="datatable.filterStatus = $event">
                    <template v-slot:columns>
                        <div class="mb-1" v-for="(column,key) in datatable.columns">
                            <b-form-checkbox
                                :id="'checkbox-'+key"
                                :name="'checkbox-'+key"
                                :value="false"
                                :unchecked-value="true"
                                v-model="column.hidden"
                                v-if="column.field!='buttons'"
                            >
                                {{ column.label }}
                            </b-form-checkbox>
                        </div>
                    </template>
                </Header>
            </template>
            <template v-slot:header-mobile>
                <HeaderMobile :title="$t('classrooms')"
                              :isNewButton="checkPermission('classroom_store')"
                              :isColumns="true"
                              @new-button-click="createFormShow"
                              @filter-div-status="datatable.filterStatus = $event">
                    <div class="mb-1" v-for="(column,key) in datatable.columns">
                        <b-form-checkbox
                            :id="'checkbox-'+key"
                            :name="'checkbox-'+key"
                            :value="false"
                            :unchecked-value="true"
                            v-model="column.hidden"
                            v-if="column.field!='buttons'"
                        >
                            {{ column.label }}
                        </b-form-checkbox>
                    </div>
                </HeaderMobile>
            </template>

            <datatable-filter v-show="datatable.filterStatus"
                              @filter="filter"
                              @filterClear="filterClear"
                              :export-excel="checkPermission('classroom_excelexport')"
                              @exportExcel="downloadExcel"
            >
                <b-row>
                    <b-col sm="12" md="6">
                        <b-form-group :label="$t('campus')">
                            <campus-selectbox v-model="datatable.queryParams.filter.campus_id"/>
                        </b-form-group>
                        <b-form-group :label="$t('building')">
                            <building-selectbox :campus_id="datatable.queryParams.filter.campus_id"
                                                v-model="datatable.queryParams.filter.building_id"/>
                        </b-form-group>
                    </b-col>
                    <b-col sm="12" md="6">
                        <b-form-group :label="$t('name')">
                            <b-form-input v-model="datatable.queryParams.filter.name"/>
                        </b-form-group>
                    </b-col>
                </b-row>
            </datatable-filter>

            <datatable :isLoading.sync="datatable.isLoading"
                       :columns="datatable.columns"
                       :rows="datatable.rows"
                       :total="datatable.total"
                       :queryParams="datatable.queryParams"
                       :lineNumbers="false"
                       @on-page-change="onPageChange"
                       @on-sort-change="onSortChange"
                       @on-per-page-change="onPerPageChange"
                       v-show="datatable.showTable"
            />
            <CommonModal ref="showModal" size="xl" :onHideOnlyX="true" @bHideModalHeaderClose="formClear()" v-if="checkPermission('classroom_show')">
                <template v-slot:CommonModalTitle>
                    {{ $t('show') }}
                </template>
                <template v-slot:CommonModalContent>
                    <show-form :formId="formId"
                               v-if="formProcess=='show'"
                    />
                </template>
            </CommonModal>
            <CommonModal ref="createFormModal" size="xl" :onHideOnlyX="true" @bHideModalHeaderClose="formClear" v-if="checkPermission('classroom_store')">
                <template v-slot:CommonModalTitle>
                    {{ $t('new').toUpperCase() }}
                </template>
                <template v-slot:CommonModalContent>
                    <create-form @createFormSuccess="createFormSuccess"
                                 v-if="formProcess=='create'"
                    />
                </template>
            </CommonModal>
            <CommonModal ref="updateFormModal" size="xl" :onHideOnlyX="true" @bHideModalHeaderClose="formClear" v-if="checkPermission('classroom_update')">
                <template v-slot:CommonModalTitle>
                    {{ $t('edit').toUpperCase() }}
                </template>
                <template v-slot:CommonModalContent>
                    <update-form :formId="formId"
                                 @updateFormSuccess="updateFormSuccess"
                                 v-if="formProcess=='update'"/>
                </template>
            </CommonModal>
        </app-layout>
    </div>
</template>
<script>
    //Template
    import AppLayout from '@/layouts/AppLayout';
    import Header from '@/layouts/AppLayout/Header'
    import HeaderMobile from '@/layouts/AppLayout/HeaderMobile'

    //Component
    import DatatableFilter from '@/components/datatable/DatatableFilter';
    import Datatable from '@/components/datatable/Datatable';
    import CommonModal from '@/components/elements/CommonModal';
    import CampusSelectbox from '@/components/interactive-fields/CampusSelectbox';
    import BuildingSelectbox from '@/components/interactive-fields/BuildingSelectbox';

    //Page
    import CreateForm from './CreateForm'
    import UpdateForm from './UpdateForm'
    import ShowForm from './ShowForm'

    //Service
    import ClassroomService from '@/services/ClassroomService';

    //Other
    import qs from 'qs';

    export default {
        components: {
            AppLayout,
            Header,
            HeaderMobile,

            DatatableFilter,
            Datatable,
            CommonModal,
            CampusSelectbox,
            BuildingSelectbox,

            CreateForm,
            UpdateForm,
            ShowForm
        },
        metaInfo() {
            return {
                title: this.$t('classroom')
            }
        },
        data() {
            return {
                formId: 0,
                form: {},
                formProcess: null,
                datatable: {
                    isLoading: false,
                    columns: [
                        {
                            label: '',
                            field: 'buttons',
                            html: true,
                            hidden: false,
                            tdClass: 'p-0 text-center w-40 align-middle',
                            buttons: [
                                {
                                    text: this.$t('show'),
                                    class: 'ri-search-eye-line',
                                    permission: 'classroom_show',
                                    callback: (row) => {
                                        this.showForm(row.id);
                                    }
                                },
                                {
                                    text: this.$t('edit'),
                                    class: 'ri-edit-box-line',
                                    permission: 'classroom_update',
                                    callback: (row) => {
                                        this.formId = row.id;
                                        this.loadData();
                                    }
                                },
                                {
                                    text: this.$t('delete'),
                                    class: 'ri-delete-bin-2-line',
                                    permission: 'classroom_delete',
                                    callback: (row) => {
                                        this.delete(row.id);
                                    }
                                }
                            ]
                        },
                        {
                            label: this.$t('campus'),
                            field: 'campus_name',
                            hidden: false
                        },
                        {
                            label: this.toUpperCase('building'),
                            field: ('building_name'),
                            hidden: false
                        },
                        {
                            label: this.toUpperCase('name'),
                            field: ('name'),
                            hidden: false
                        },
                        {
                            label: this.toUpperCase('capacity'),
                            field: 'capacity',
                            hidden: false
                        },
                        {
                            label: this.toUpperCase('exam_capacity'),
                            field: 'exam_capacity',
                            hidden: false
                        }
                    ],
                    rows: [],
                    total: 0,
                    filterStatus: true,
                    showTable: false,
                    queryParams: {
                        filter: {},
                        sort: 'id',
                        page: 1,
                        limit: 20
                    }
                }
            };
        },
        methods: {
            filter() {
                this.datatable.queryParams.page = 1;
                this.getRows();
            },
            filterClear() {
                this.datatable.queryParams.filter = {};
                this.getRows();
            },
            onPageChange(params) {
                this.datatable.queryParams.page = params.currentPage;
                this.getRows();
            },
            onPerPageChange(params) {
                this.datatable.queryParams.limit = params.currentPerPage;
                this.datatable.queryParams.page = 1;
                this.getRows();
            },
            onSortChange(params) {
                const sortType = params[0].type == 'desc' ? '-' : '';
                this.datatable.queryParams.sort = sortType + params[0].field;
                this.getRows();
            },
            getRows() {
                this.datatable.showTable = true;
                this.datatable.isLoading = true;

                const config = {
                    params: {
                        ...this.datatable.queryParams
                    },
                    paramsSerializer: (params) => qs.stringify(params, {encode: false})
                };

                return ClassroomService.getAll(config)
                                       .then((response) => {
                                           this.datatable.rows = response.data.data;
                                           this.datatable.total = response.data.pagination.total;
                                       })
                                       .finally(() => {
                                           this.datatable.isLoading = false;
                                       });
            },
            loadData() {
                this.formProcess = 'update';
                ClassroomService.get(this.formId)
                                .then((response) => {
                                    this.form = response.data.data;
                                    this.$refs.updateFormModal.$refs.commonModal.show();
                                })
                                .catch((error) => {
                                    if (error.data.message) {
                                        this.$toast.error(this.$t('api.' + error.data.message));
                                    }
                                });
            },
            delete(id) {
                this.deleteModal(() => {
                    ClassroomService.deleteClassroom(id)
                                    .then((response) => {
                                        this.filter();
                                        this.$toast.success(this.$t('api.' + response.data.message));
                                    })
                                    .catch((error) => {
                                        this.$toast.error(this.$t('api.' + error.data.message));
                                    });
                });
            },
            async createFormSuccess() {
                this.$refs.createFormModal.$refs.commonModal.hide()
                this.getRows()
                this.formClear()
            },
            async updateFormSuccess() {
                this.$refs.updateFormModal.$refs.commonModal.hide()
                this.getRows()
                this.formClear()
            },
            createFormShow() {
                this.formId = null
                this.form = {};
                this.formProcess = 'create'
                this.$refs.createFormModal.$refs.commonModal.show()
            },
            formClear() {
                this.formId = null
                this.formProcess = null
            },
            showForm(id) {
                this.formId = id
                this.formProcess = 'show'
                this.$refs.showModal.$refs.commonModal.show()
            },
            downloadExcel() {
                const config = {
                    params: {
                        ...this.datatable.queryParams
                    },
                    paramsSerializer: (params) => qs.stringify(params, {encode: false})
                };

                ClassroomService.downloadAsExcel(config)
                                .then(res => this._downloadFile(res, this.$t('classrooms') + '.xlsx'))
                                .catch(err => this.showErrors(err))
            }
        }
    }
</script>
